import { useEffect, useState } from 'react';
import { Check, X, Loader2 } from 'lucide-react';
import { CalendlyService } from '../lib/services/calendly/calendlyService';
import { TokenService } from '../lib/services/calendly/tokenService';

export default function CalendlyStatus() {
  const [status, setStatus] = useState<'checking' | 'connected' | 'disconnected'>('checking');

  useEffect(() => {
    async function checkConnection() {
      try {
        const tokens = await TokenService.getStoredTokens('calendly');
        if (!tokens) {
          setStatus('disconnected');
          return;
        }

        // Verify connection by attempting to get user data
        await CalendlyService.getCurrentUser();
        setStatus('connected');
      } catch (error) {
        console.error('Error checking Calendly connection:', error);
        setStatus('disconnected');
      }
    }

    checkConnection();
  }, []);

  if (status === 'checking') {
    return (
      <div className="flex items-center space-x-2 text-gray-600">
        <Loader2 className="h-4 w-4 animate-spin" />
        <span>Checking Calendly connection...</span>
      </div>
    );
  }

  return (
    <div className="flex items-center space-x-2">
      {status === 'connected' ? (
        <>
          <div className="flex items-center space-x-2 text-green-600">
            <Check className="h-4 w-4" />
            <span>Connected to Calendly</span>
          </div>
        </>
      ) : (
        <div className="flex items-center space-x-2 text-gray-600">
          <X className="h-4 w-4" />
          <span>Not connected to Calendly</span>
        </div>
      )}
    </div>
  );
}