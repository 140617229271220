import axios from 'axios';
import { auth } from '../../firebase';
import { CalendlyTokens, CalendlyUser, CalendlyEvent } from '../../types/calendly';
import { TokenService } from './tokenService';
import { toast } from 'react-hot-toast';

// Updated API URLs
const CALENDLY_AUTH_URL = 'https://auth.calendly.com/oauth/authorize';
const CALENDLY_TOKEN_URL = 'https://auth.calendly.com/oauth/token';
const CALENDLY_API_URL = 'https://api.calendly.com/v2';

export class CalendlyService {
  private static REDIRECT_URI = `${window.location.origin}/calendly/callback`;

  static generateAuthUrl(): string {
    const state = TokenService.generateRandomState();
    localStorage.setItem('calendly_oauth_state', state);

    const params = new URLSearchParams({
      client_id: import.meta.env.VITE_CALENDLY_CLIENT_ID || '',
      response_type: 'code',
      redirect_uri: this.REDIRECT_URI,
      state,
      scope: 'default'
    });

    return `${CALENDLY_AUTH_URL}?${params.toString()}`;
  }

  static async handleAuthCallback(code: string, state: string): Promise<void> {
    const storedState = localStorage.getItem('calendly_oauth_state');
    if (state !== storedState) {
      throw new Error('Invalid state parameter');
    }

    try {
      const params = new URLSearchParams({
        grant_type: 'authorization_code',
        client_id: import.meta.env.VITE_CALENDLY_CLIENT_ID || '',
        client_secret: import.meta.env.VITE_CALENDLY_CLIENT_SECRET || '',
        code,
        redirect_uri: this.REDIRECT_URI
      });

      const response = await axios.post(CALENDLY_TOKEN_URL, params.toString(), {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      });

      await TokenService.storeTokens(response.data, 'calendly');
      localStorage.removeItem('calendly_oauth_state');
    } catch (error) {
      console.error('Calendly auth error:', error);
      throw error;
    }
  }

  static async getCurrentUser(): Promise<CalendlyUser> {
    const response = await this.makeCalendlyRequest<{ resource: CalendlyUser }>('/users/me');
    return response.resource;
  }

  static async getEventTypes(): Promise<CalendlyEvent[]> {
    const user = await this.getCurrentUser();
    // Updated endpoint to use the correct path
    const response = await this.makeCalendlyRequest<{ collection: CalendlyEvent[] }>(
      `/scheduling_links?organization=${user.uri}&active=true`
    );
    return response.collection;
  }

  static async disconnect(): Promise<void> {
    const user = auth.currentUser;
    if (!user) {
      throw new Error('No authenticated user');
    }

    try {
      await TokenService.removeTokens('calendly');
      toast.success('Successfully disconnected from Calendly');
    } catch (error) {
      console.error('Error disconnecting from Calendly:', error);
      throw error;
    }
  }

  private static async makeCalendlyRequest<T>(
    endpoint: string,
    method: string = 'GET',
    data?: unknown
  ): Promise<T> {
    const tokens = await TokenService.getStoredTokens('calendly');
    if (!tokens) throw new Error('No Calendly tokens available');

    let accessToken = tokens.accessToken;

    if (Date.now() >= tokens.expiresAt) {
      accessToken = await TokenService.refreshAccessToken(tokens.refreshToken, 'calendly');
    }

    try {
      const response = await axios({
        method,
        url: `${CALENDLY_API_URL}${endpoint}`,
        headers: {
          'Authorization': `Bearer ${accessToken}`,
          'Content-Type': 'application/json'
        },
        data
      });

      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error) && error.response?.status === 401) {
        accessToken = await TokenService.refreshAccessToken(tokens.refreshToken, 'calendly');
        
        const retryResponse = await axios({
          method,
          url: `${CALENDLY_API_URL}${endpoint}`,
          headers: {
            'Authorization': `Bearer ${accessToken}`,
            'Content-Type': 'application/json'
          },
          data
        });

        return retryResponse.data;
      }
      throw error;
    }
  }
}